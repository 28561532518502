import { AdminVoter, AppSubscriptionVoter, AppVoter, LocationVoter, PermissionService, PermissionTemplateGroupVoter, PropertyGroupVoter, PropertyVoter, ReportTopicVoter, ShortcutVoter, SortVoter, useAuthStore, useLocationStore, UserGroupVoter, UserVoter } from "@verbleif/shared";

export const permissionService = new PermissionService({
  setUserPermissions: () => {
    const result = useAuthStore().user.value?.permissions ?? [];
    console.log("[DionDebug]]", result);
    return result;
  },
  setUserIri: () => useAuthStore().user.value?.["@id"] ?? null,
  setMode: () => useLocationStore().mode.value,
  setDefaultLocation: () => useLocationStore().modeSelectedLocation.value,
  setDefaultClient: () => useLocationStore().modeSelectedClient.value,
  setLocationClientMap: () => {
    return new Map();
  },
  setUsersData: () => {
    return new Map();
  },
});

export const appSubscriptionVoter = new AppSubscriptionVoter(permissionService);
export const appVoter = new AppVoter(permissionService);
export const locationVoter = new LocationVoter(permissionService);
export const permissionTemplateGroupVoter = new PermissionTemplateGroupVoter(permissionService);
export const propertyGroupVoter = new PropertyGroupVoter(permissionService);
export const propertyVoter = new PropertyVoter(permissionService);
export const shortcutVoter = new ShortcutVoter(permissionService);
export const sortVoter = new SortVoter(permissionService);
export const userGroupVoter = new UserGroupVoter(permissionService);
export const userVoter = new UserVoter(permissionService);
export const reportTopicVoter = new ReportTopicVoter(permissionService);
export const adminVoter = new AdminVoter(permissionService);
