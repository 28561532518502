<script>
import {
  LoopbackFilterLogicalOperatorEnum,
  LoopbackFilterOperatorEnum,
  provideModal,
  providePaginate,
  useStringToHsl,
} from "@verbleif/lib";
import { api } from "@verbleif/shared";
import { useI18n } from "vue-i18n";
import { useActiveToggle } from "../Settings/Composables/useActiveToggle";
import useCreate from "../Settings/Composables/useCreate";
import useEdit from "../Settings/Composables/useEdit";
import useRemove from "../Settings/Composables/useRemove";
import { useFeatureTransformer } from "./useFeatureTransformer";

export default {
  name: "FeaturesOverview",
  setup() {
    const ENDPOINT = "/features";
    const TRANSLATION_KEY = "features";
    const { t } = useI18n();
    const { stringToHslColor } = useStringToHsl();
    const { load } = providePaginate({
      initialPerPage: 50,
      tableFields: {
        type: t(`${TRANSLATION_KEY}.type`),
        name: t(`${TRANSLATION_KEY}.name`),
        alias: t(`${TRANSLATION_KEY}.alias`),
        description: t(`${TRANSLATION_KEY}.description`),
        active: t(`${TRANSLATION_KEY}.active`),
      },
      sortableFields: {
        type: t(`${TRANSLATION_KEY}.type`),
        name: t(`${TRANSLATION_KEY}.name`),
        alias: t(`${TRANSLATION_KEY}.alias`),
        active: t(`${TRANSLATION_KEY}.active`),
      },
      searchableFields: [
        {
          name: "name",
          operator: LoopbackFilterOperatorEnum.ILIKE,
          logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
        },
        {
          name: "description",
          operator: LoopbackFilterOperatorEnum.ILIKE,
          logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
        },
        {
          name: "alias",
          operator: LoopbackFilterOperatorEnum.ILIKE,
          logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
        },
      ],
      onLoad: options => api.get(ENDPOINT, options),
    });
    const modalManager = provideModal();
    const { transformer } = useFeatureTransformer();
    const { showCreate } = useCreate({
      component: import("./CreateFeature.vue"),
      endpoint: ENDPOINT,
      translationKey: TRANSLATION_KEY,
      modalManager,
      transformer,
      load,
    });
    const { showEdit } = useEdit({
      component: import("./EditFeature.vue"),
      endpoint: ENDPOINT,
      translationKey: TRANSLATION_KEY,
      modalManager,
      transformer,
      load,
    });
    const { showActiveToggle } = useActiveToggle({
      endpoint: ENDPOINT,
      translationKey: TRANSLATION_KEY,
      load,
    });
    const { showRemove } = useRemove({
      endpoint: ENDPOINT,
      translationKey: TRANSLATION_KEY,
      load,
    });

    load();

    return {
      t,
      TRANSLATION_KEY,
      stringToHslColor,
      visible: modalManager.visible,
      showCreate,
      showEdit,
      showActiveToggle,
      showRemove,
    };
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="container">
      <div class="header">
        <div class="title">
          {{ t(`${TRANSLATION_KEY}.title`) }}
        </div>
        <div class="button-group">
          <VButton variant="is-primary" icon-left="plus" @click="showCreate">
            {{ t("base.table.new_button") }}
          </VButton>
        </div>
      </div>
      <div class="table-controls">
        <VSort
          :placeholder-text="t('table.sort_placeholder')"
        />
        <VSearch
          :placeholder-text="t('table.search_placeholder')"
          :clear-search-text="t('table.clear_search')"
        />
      </div>
      <div class="v-table-container">
        <VTable
          :empty-text="
            t('table.empty', { m: t(`${TRANSLATION_KEY}.module`, 2) })
          "
          :loading-text="t('table.loading')"
          sortable-heading
          :on-row-click="(item) => showEdit(item)"
        >
          <template #active="{ item, loading }">
            <VSkeleton v-if="loading" dynamic-width :height="10" />
            <template v-else>
              {{ item.active === 1 ? t("base.yes") : t("base.no") }}
            </template>
          </template>
          <template #right="{ item, loading }">
            <template v-if="loading">
              <VSkeleton
                :dynamic-width="false"
                :width="15"
                width-unit="px"
                :height="15"
              />
            </template>
            <template v-else>
              <VDropdown
                class="inline p-2"
                theme="verbleif-popper"
                placement="bottom-end"
              >
                <template #popper>
                  <div class="options">
                    <VDropdownItem
                      close-on-click
                      :text="t('table.edit')"
                      :icon="['fas', 'pencil']"
                      @click="() => showEdit(item)"
                    />
                    <VDropdownItem
                      close-on-click
                      :text="t(`base.table.toggle_active_${item.active}`)"
                      :icon="
                        !!item.active ? ['fas', 'user-slash'] : ['fas', 'user']
                      "
                      :class="[!item.active ? 'is-primary' : 'is-danger']"
                      @click="() => showActiveToggle(item)"
                    />
                    <VDropdownItem
                      class="is-danger"
                      close-on-click
                      :text="t('table.remove')"
                      :icon="['fas', 'trash']"
                      :disabled="item.active"
                      @click="() => showRemove(item)"
                    />
                  </div>
                </template>
                <FontAwesomeIcon
                  class="options-trigger"
                  icon="ellipsis-h"
                  @click.stop
                />
              </VDropdown>
            </template>
          </template>
        </VTable>
        <div class="table-controls is-bottom">
          <VPaginate
            :next-text="t('base.next')"
            :previous-text="t('base.previous')"
          />
          <VPerPage
            :per-page-text="t('table.per_page')"
            :options="{
              10: t('table.x_items_count', { c: 10 }),
              20: t('table.x_items_count', { c: 20 }),
              50: t('table.x_items_count', { c: 50 }),
              100: t('table.x_items_count', { c: 100 }),
            }"
          />
        </div>
      </div>
    </div>
    <transition name="fade">
      <VModal v-if="visible" />
    </transition>
  </div>
</template>

<style lang="scss" src="./Features.scss" scoped></style>
