import type { UserHydraItem } from "@verbleif/lib";
import type {
  AuthResponse,
} from "@verbleif/shared";
import type { AxiosRequestConfig } from "axios";
import router from "@cms/core/plugin/router";
import { permissionService } from "@cms/features/Permissions";
import * as Sentry from "@sentry/vue";
import {
  api,
  useAuthStore,
} from "@verbleif/shared";

const authStore = useAuthStore();

export async function onAuthenticateSuccess(response: AuthResponse, isLogin: boolean = false): Promise<void> {
  authStore.setToken(response.access_token);
  authStore.setRefreshToken(response.refresh_token);

  const user = await api.get<UserHydraItem>("/api/users/me", {
    _retry: false,
  } satisfies AxiosRequestConfig & { _retry: boolean } as any)
    .then(r => r.data);

  authStore.setUser(user);

  permissionService.init();

  if (import.meta.env.PROD) {
    Sentry.setUser(user);
  }
  if (isLogin) {
    await router.push({ name: "client-overview" });
  }
}
