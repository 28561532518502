import router from "@cms/core/plugin/router";
import { useAuthStore, useConfigStore, useImpersonationStore } from "@verbleif/shared";

export function useLogout() {
  const authStore = useAuthStore();
  const { config } = useConfigStore();
  const impersonationStore = useImpersonationStore();

  async function logout(redirectToLogin = true) {
    if (!config.value) {
      throw new Error("Config not set");
    }

    if (redirectToLogin) {
      authStore.reset();
      impersonationStore.reset();

      if (import.meta.env.VITE_SKIP_OAUTH !== "true") {
        await router.push({ name: "login" });
      } else {
        window.open(`${config.value.loginUrl}/logout`, "_self");
      }
    }
  }

  return { logout };
}
