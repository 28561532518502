<script setup lang="ts">
import type { EnvironmentKey } from "@cms/features/ConfigOverride/configPresets";
import { config } from "@cms/features/ConfigOverride/configPresets";
import { disableMercure } from "@verbleif/lib";
import { useAuthStore, useConfigStore, useLocationStore } from "@verbleif/shared";
import { useMagicKeys, whenever } from "@vueuse/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { configOverrideDialogVisible } from "./configOverrideDialogRef";
import CustomUrlDialog from "./CustomUrlDialog.vue";

withDefaults(defineProps<{
  loading?: boolean
}>(), {
  loading: false,
});

const emit = defineEmits<{
  (e: "close"): void
}>();

const { Ctrl_Shift_D } = useMagicKeys();
whenever(Ctrl_Shift_D, () => {
  configOverrideDialogVisible.value = true;
});

const configStore = useConfigStore();
const authStore = useAuthStore();
const locationStore = useLocationStore();
const { info } = useToast();
const { t } = useI18n();
function setPreset(presetName: EnvironmentKey) {
  const preset = config[presetName];
  if (!preset) {
    throw new Error(`Preset not found: ${presetName}`);
  }

  configStore.setOverrideConfig({ presetName, ...preset });
  info(t("settings.configOverride.overrideApplied"));
}

function toggleMercure() {
  disableMercure.value = !disableMercure.value;
  info(t(`settings.configOverride.${disableMercure.value ? "disableMercure" : "enableMercure"}`));
}

function reset() {
  configStore.reset();
  // systemStore.reset();
  // locationStore.reset();
  // authStore.reset();
  info(t("settings.configOverride.resetSuccessful"));
}

const configOverrideDialog = ref(false);

function closeDialog() {
  emit("close");
}
</script>

<template>
  <VDialog
    id="config-override-dialog"
    v-model:visible="configOverrideDialogVisible"
    mask
    size="large"
    @close="closeDialog"
  >
    <template #title>
      <span class="dark:text-white">{{ t("settings.configOverride.title") }}</span>
    </template>
    <template #content>
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-0.5">
          <div class="text-sm text-gray-800 dark:text-gray-300">
            {{ t("settings.configOverride.environmentPresets") }}
          </div>
          <div class="flex flex-wrap gap-2">
            <VButton
              :variant="!!configStore.config.value?.presetName ? 'is-info is-outlined' : 'is-info'"
              size="is-small"
              @click="() => configOverrideDialog = true"
            >
              {{ t("settings.configOverride.custom") }}
            </VButton>
            <VButton
              :variant="configStore.config.value?.presetName === 'localhost' ? 'is-primary' : 'is-primary is-outlined'"
              size="is-small"
              @click="() => setPreset('localhost')"
            >
              {{ t("settings.configOverride.localhost") }}
            </VButton>
            <VButton
              :variant="configStore.config.value?.presetName === 'testing' ? 'is-primary' : 'is-primary is-outlined'"
              size="is-small"
              @click="() => setPreset('testing')"
            >
              {{ t("settings.configOverride.dev") }}
            </VButton>
            <VButton
              :variant="configStore.config.value?.presetName === 'staging' ? 'is-warning' : 'is-warning is-outlined'"
              size="is-small"
              @click="() => setPreset('staging')"
            >
              {{ t("settings.configOverride.staging") }}
            </VButton>
            <VButton
              :variant="configStore.config.value?.presetName === 'production' ? 'is-danger' : 'is-danger is-outlined'"
              size="is-small"
              @click="() => setPreset('production')"
            >
              {{ t("settings.configOverride.production") }}
            </VButton>
          </div>
        </div>

        <div class="flex flex-col gap-0.5">
          <div class="text-sm text-gray-800 dark:text-gray-300">
            {{ t("settings.configOverride.configStore") }}
          </div>
          <div class="flex">
            <VButton
              :variant="disableMercure ? 'is-danger is-outlined' : 'is-info is-outlined'"
              size="is-small"
              @click="() => toggleMercure()"
            >
              {{ t(`settings.configOverride.${disableMercure ? "enableMercure" : "disableMercure"}`) }}
            </VButton>
          </div>
        </div>

        <div class="flex flex-col gap-0.5">
          <div class="text-sm text-gray-800 dark:text-gray-300">
            {{ t("settings.configOverride.authStore") }}
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex">
              <VButton
                variant="is-danger is-outlined"
                size="is-small"
                icon-left="undo"
                @click="() => authStore.reset()"
              >
                {{ t("settings.configOverride.reset") }}
              </VButton>
            </div>
            <VCheckbox
              name="hasToken"
              :value="authStore.hasToken.value"
              :model-value="authStore.hasToken.value"
              :label-right="t('settings.configOverride.hasToken')"
              :disabled="true"
              class="dark:text-gray-300"
            />
            <VCheckbox
              name="isAuthenticated"
              :value="authStore.isAuthenticated.value"
              :model-value="authStore.isAuthenticated.value"
              :label-right="t('settings.configOverride.isAuthenticated')"
              :disabled="true"
              class="dark:text-gray-300"
            />
            <VCheckbox
              name="isRemembered"
              :value="authStore.isRemembered.value"
              :model-value="authStore.isRemembered.value"
              :label-right="t('settings.configOverride.isRemembered')"
              :disabled="true"
              class="dark:text-gray-300"
            />
          </div>
        </div>

        <div class="flex flex-col gap-0.5">
          <div class="text-sm text-gray-800 dark:text-gray-300">
            {{ t("settings.configOverride.locationStore") }}
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex">
              <VButton
                variant="is-danger is-outlined"
                size="is-small"
                icon-left="undo"
                @click="() => locationStore.reset()"
              >
                {{ t("settings.configOverride.reset") }}
              </VButton>
            </div>
            <VCheckbox
              v-model="locationStore.hasMultipleLocations.value"
              name="hasMultipleLocations"
              :value="locationStore.hasMultipleLocations.value"
              :label-right="t('settings.configOverride.hasMultipleLocations')"
              :disabled="true"
              class="dark:text-gray-300"
            />
            <div class="flex gap-4 flex-col text-sm text-gray-800 dark:text-gray-300">
              <span>{{ t("settings.configOverride.defaultLocation") }}: {{ locationStore.defaultLocation.value || "-" }}</span>
              <span>{{ t("settings.configOverride.locationCount") }}: {{ locationStore.locations.value?.length || 0 }}</span>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-0.5">
          <div class="text-sm text-gray-800 dark:text-gray-300">
            {{ t("settings.configOverride.systemStore") }}
          </div>
          <div class="flex flex-col gap-2">
            <VCheckbox
              v-model="configStore.debugMode.value"
              name="debugMode"
              :value="configStore.debugMode.value"
              :label-right="t('settings.configOverride.debugMode')"
              :true-value="true"
              :false-value="false"
              class="dark:text-gray-300"
              @update:model-value="configStore.debugMode.value = $event as boolean"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer="{ close }">
      <div class="w-full">
        <VDivider class="w-full !mb-6" />
        <div class="flex flex-col sm:flex-row justify-between gap-4">
          <VButton rounded class="is-gray" @click="close">
            {{ t("base.cancel") }}
          </VButton>
          <VButton
            rounded
            outlined
            icon-left="undo"
            class="is-danger"
            @click="reset"
          >
            {{ t("settings.configOverride.revertToDefault") }}
          </VButton>
        </div>
      </div>
    </template>
  </VDialog>

  <CustomUrlDialog
    :visible="configOverrideDialog"
    @close="configOverrideDialog = false"
  />
</template>

<style scoped></style>
