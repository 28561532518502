import AuthRoutes from "@cms/features/Auth/AuthRoutes";
import ClientsRoutes from "@cms/features/Clients/ClientsRoutes";
import FeatureRoutes from "@cms/features/Features/FeatureRoutes";
import ProductRoutes from "@cms/features/Products/ProductRoutes.ts";
import { useAuthStore } from "@verbleif/shared";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  ...ClientsRoutes,
  ...AuthRoutes,
  ...FeatureRoutes,
  ...ProductRoutes,
];
const { returnUrl, hasToken, isRemembered } = useAuthStore();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
export default router;

router.beforeEach((to, _, next) => {
  console.log("to", to);
  if (to.meta.skipAuthChecks) {
    console.log("Skip Auth Checks");

    return next();
  }

  const isUnauthenticated = !hasToken.value && !isRemembered.value;

  if (isUnauthenticated && !to.meta.public) {
    returnUrl.value = to.fullPath;
    return next({ name: "login" });
  }

  const fallback = to.params.redirect as string
    || to.query.redirect as string
    || { name: "client-overview" };

  const isAuthenticated = hasToken.value || isRemembered.value;
  if (isAuthenticated && to.meta.public) {
    return next(fallback);
  }

  next();
});
