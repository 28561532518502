import type { AppConfig } from "@verbleif/shared";

export type EnvironmentKey = keyof typeof config;

export const config = <Record<string, AppConfig>>{
  "localhost": {
    apiUrl: "https://api.verbleif.localhost",
    mercureUrl: "https://mercure.verbleif.localhost:81",
    cdnUrl: "https://cdn.verbleif.localhost",
    webUrl: "http://verbleif.localhost:5173",
    appEnv: "local",
    loginUrl: "https://login.verbleif.dev",
    loginRedirect: "http://cms.verbleif.localhost:5174",
    loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",

  },
  "cms.verbleif.dev": {
    apiUrl: "https://api.verbleif.dev",
    mercureUrl: "https://mercure.verbleif.dev",
    cdnUrl: "https://cdn.verbleif.dev",
    webUrl: "https://app.verbleif.dev",
    appEnv: "dev",
    loginUrl: "https://login.verbleif.dev",
    loginRedirect: "http://cms.verbleif.dev",
    loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
  },
  "cms.staging.verbleif.com": {
    apiUrl: "https://api.staging.verbleif.com",
    mercureUrl: "https://mercure.staging.verbleif.com",
    cdnUrl: "https://cdn.staging.verbleif.com",
    webUrl: "https://app.staging.verbleif.com",
    appEnv: "staging",
    loginUrl: "https://login.staging.verbleif.com",
    loginRedirect: "http://cms.staging.verbleif.com",
    loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
  },
  "cms.verbleif.com": {
    apiUrl: "https://api.verbleif.com",
    mercureUrl: "https://mercure.verbleif.com",
    cdnUrl: "https://cdn.verbleif.com",
    webUrl: "https://app.verbleif.com",
    appEnv: "production",
    loginUrl: "https://login.verbleif.com",
    loginRedirect: "http://cms.verbleif.com",
    loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
  },
};
